.companies {
  padding: 50px 0;
  background: #E9E8E9;
  &_alt {
    background: #ffffff;
    h2 {
      margin-bottom: 48px;
    }
  }
  @media (max-width: 768px) {
    padding: 36px 0;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 48px;
    }
    &-item {
      display: flex;
      align-items: center;
      filter: grayscale(1);
      transition: 0.5s;
      &:not(:last-child) {
        margin-right: 24px;
        @media (max-width: 768px) {
          margin-right: 0;
        }
      }
      &:hover {
        filter: grayscale(0);
      }
      img {
        max-width: 100%;
      }
    }
  }
}