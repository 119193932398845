.button {
  padding: 15px 40px;
  color: #ffffff;
  background: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%);
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &_border {
    background: none;
    border: 2px solid;
    border-image: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%) 1;
  }
  &_big {
    padding: 24px 60px;
    font-size: 20px;
  }
  &_white {
    background: #ffffff;
    span {
      background: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  &_gradient {
    span {
      background: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  &_rounded {
    border-radius: 12px;
  }
}