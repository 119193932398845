.header {
  padding: 24px 0;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  &_opened {
    background: #1A191E;
  }
  &_alt {
    background: #1A191E;
  }

  :global(.container) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1280px) {
      position: static;
    }
  }

  &__logo {
    display: flex;
  }

  &__menu {
    display: flex;
    list-style-type: none;

    &-item {
      font-size: 20px;
      &:not(:last-child) {
        margin-right: 60px;
      }
      a {
        text-decoration: none;
        color: #ffffff;
      }
      &__dot {
        position: absolute;
        top: calc(100% + 8px);
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        div {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%);
        }
        &::before {
          content: '';
          position: absolute;
          width: 70px;
          height: 20px;
          backdrop-filter: blur(5px);
          z-index: 1;
          bottom: 5px;
        }
      }
      &_active {

      }
    }
    @media (max-width: 1280px) {
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      height: calc(100vh - 100% + 1px);
      width: 100%;
      padding: 0 24px;
      background: #1A191E;
      flex-direction: column;
      align-items: flex-start;
      &-item {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 18px;
        }
        &__dot {
          left: calc(100% + 24px);
          top: 50%;
          transform: translateY(-50%);
          &::before {
            width: 35px;
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 16px;
  }

  &__burger {
    width: 50px;
    height: 36px;
    span {
      position: absolute;
      display: block;
      background: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%);
      height: 8px;
      width: 50px;
      transition: 0.3s;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 14px;
      }
      &:nth-child(3) {
        top: 28px;
      }
    }
    &_opened {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 14px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          top: 14px;
        }
      }
    }
  }
}