.team {
  padding-top: 120px;
  padding-bottom: 108px;
  @media (max-width: 1024px) {
    padding: 64px 0;
  }
  :global(.container) {
    > h2 {
      text-align: center;
      margin-bottom: 16px;
    }
    > p {
      text-align: center;
      margin-bottom: 72px;
    }
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 64px;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    &-item {
      $p: &;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      &__photo {
        width: 100%;
        padding-bottom: 100%;
        margin-bottom: 24px;
        > div {
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        &::before {
          content: '';
          background: linear-gradient(270deg, #D858AE 0%, #4F69C1 100%);
          opacity: 0.3;
          filter: blur(38px);
          width: 200px;
          height: 200px;
          border-radius: 50%;
          position: absolute;
          bottom: 64px;
        }
        &::after {
          content: '';
          height: 100px;
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
          z-index: 1;
        }
        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          mix-blend-mode: luminosity;
          filter: grayscale(1);
        }
      }
      &:nth-of-type(3n - 2) {
        #{$p}__photo {
          &::before {
            left: 0;
          }
        }
      }
      &:nth-of-type(3n - 1) {
        #{$p}__photo {
          &::before {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      &:nth-of-type(3) {
        #{$p}__photo {
          &::before {
            right: 0;
          }
        }
      }

      h3 {
        margin-bottom: 8px;
      }
      span {
        font-size: 24px;
        color: #1A191E;
        margin-bottom: 12px;
      }
      p {
        font-size: 20px;
        margin-bottom: 24px;
        max-width: 338px;
      }
      &__discord {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 60px;
        img:nth-child(1) {
          position: absolute;
          top: 0;
          width: 30px;
        }
      }
    }
  }
}