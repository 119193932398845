.footer {
  padding: 60px 0;
  background: #1A191E url("./../../assets/images/footer/background.svg") no-repeat right bottom;
  @media (max-width: 768px) {
    background-size: 75% auto;
  }

  :global(.container) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 180px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__logo {
    display: flex;
    @media (max-width: 768px) {
      margin-bottom: 24px;
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    span {
      font-size: 20px;
      color: #ffffff;
      margin-bottom: 12px;
    }
    ul {
      list-style-type: none;
      display: flex;
      @media (max-width: 1024px) {
        flex-direction: column;
      }
      li {
        &:not(:last-child) {
          margin-right: 48px;
          @media (max-width: 1024px) {
            &::after {
              display: none;
            }
            margin-right: 0;
            margin-bottom: 12px;
          }
          &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 18px;
            background: #97979E;
            right: -24px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        a {
          text-decoration: none;
          color: #97979E;
        }
      }
    }
  }
}