.input {
  width: 100%;
  &__icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  input {
    width: 100%;
    padding: 20px 0;
    padding-left: 36px;
    color: #ffffff;
    font-size: 24px;
    border-bottom: 2px solid #303034;
    transition: 0.5s;
    &::placeholder {
      color: #97979E;
    }
    &:focus {
      border-color: #ffffff;
    }
  }
  &_labeled {
    display: flex;
    flex-direction: column;
    span {
      font-size: 20px;
      color: #97979E;
      margin-bottom: 8px;
    }
    input {
      padding: 20px 24px;
      border: 1px solid #D8D8D8;
      font-size: 20px;
      color: #000000;
      &:focus {
        border-color: #D8D8D8;
      }
    }
  }
  &_small {
    span {
      font-size: 16px;
      margin-bottom: 4px;
    }
    input {
      padding: 16px 20px;
      border: 1px solid #D8D8D8;
      font-size: 16px;
      color: #000000;
      &:focus {
        border-color: #D8D8D8;
      }
    }
  }
}