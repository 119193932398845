.services {
  padding-top: 100px;
  padding-bottom: 72px;
  background: #1A191E;
  $p: &;
  &_alt {
    padding-top: 200px;
  }
  :global(.container) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 70px;
    @media (max-width: 1024px) {
      flex-direction: column;
      text-align: center;
    }
    h2 {
      color: #ffffff;
      @media (max-width: 1024px) {
        br {
          display: none;
        }
        margin-bottom: 24px;
      }
    }
    p {
      max-width: 670px;
    }
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    gap: 30px;
    margin-bottom: 72px;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    &-item {
      padding: 64px 40px 36px;
      text-decoration: none;
      border: 2px solid;
      border-image: linear-gradient(180deg, #42404D 0%, rgba(26, 25, 30, 0) 100%) 1;
      $i: &;

      &_alt {
        border-radius: 16px;
        border: 2px solid #42404D;
        overflow: hidden;
        padding: 48px 16px;
        > p {
          font-size: 16px;
        }
      }
      &__icon {
        margin-bottom: 64px;
        z-index: 1;
      }
      h3 {
        color: #ffffff;
        margin-bottom: 8px;
        z-index: 1;
      }
      p {
        margin-bottom: 24px;
        z-index: 1;
      }
      img {
        z-index: 1;
      }
      &__info {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        img {
          margin-bottom: 0;
          margin-right: 16px;
        }
        h3 {
          margin-bottom: 0;
        }
      }
      &::before {
        content: '';
        width: 227px;
        height: 159px;
        position: absolute;
        background: url("./../../assets/images/services/figure.svg");
      }
      &::after {
        content: '';
      }
      &:nth-of-type(3n - 2) {
        &::before {
          transform: rotate(180deg);
          top: 0;
          left: 0;
        }
      }
      &:nth-of-type(3n - 1) {
        &::before {
          right: 0;
          bottom: 0;
        }
      }
      &:nth-of-type(3n) {
        &::before {
          transform: scaleX(-1) rotate(180deg);
          right: 0;
          top: 0;
        }
      }
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(71, 68, 82, 0.1);
        backdrop-filter: blur(25px);
      }
    }
  }
}