.about {
  padding: 120px 0;
  $p: &;
  &_alt {
    padding-top: 220px;
    padding-bottom: 0;
    #{$p}__we {
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    #{$p}__info {
      > p {
        margin-bottom: 48px;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 24px 0;
    &_alt {
      padding-top: 140px;
    }
  }
  :global(.container) {
    display: flex;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
  &__we {
    padding: 72px 50px 50px;
    min-width: 440px;
    background: url("./../../assets/images/about/background.svg") no-repeat;
    background-size: cover;
    margin-right: 80px;
    @media (max-width: 1024px) {
      min-width: auto;
      margin-right: 0;
      margin-bottom: 48px;
    }
    h1 {
      font-size: 80px;
      color: #ffffff;
      margin-bottom: 156px;
      @media (max-width: 1024px) {
        font-size: 60px;
      }
    }
  }
  &__info {
    h2 {
      margin-bottom: 24px;
    }
    p {
      margin-bottom: 12px;
      max-width: 821px;
    }
    img {
      max-width: 100%;
    }
  }
  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 18px 12px;
    &-item {
      padding: 22px 36px;
      border-radius: 100px;
      border: 2px solid #1A191E;
      font-size: 24px;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 20px;
        padding: 16px 24px;
      }
      &_pink {
        background: #F9E6F3;
        border: 2px solid #D858AE;
        color: #D858AE;
      }
      &_blue {
        background: #E7E8F5;
        border: 2px solid #4F69C1;
        color: #4F69C1;
      }
    }
  }
}