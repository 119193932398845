@font-face {
  font-family: 'Helvetica Inserat';
  src: local('Helvetica Inserat Roman'), local('Helvetica-Inserat-Roman'),
  url('helvetica-inserat/HelveticaInseratLTStd-Roman.woff2') format('woff2'),
  url('helvetica-inserat/HelveticaInseratLTStd-Roman.woff') format('woff'),
  url('helvetica-inserat/HelveticaInseratLTStd-Roman.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('helvetica-neue/HelveticaNeueCyr-Roman.eot');
  src: local('HelveticaNeueCyr-Roman'),
  url('helvetica-neue/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
  url('helvetica-neue/HelveticaNeueCyr-Roman.woff2') format('woff2'),
  url('helvetica-neue/HelveticaNeueCyr-Roman.woff') format('woff'),
  url('helvetica-neue/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('helvetica-neue/HelveticaNeueCyr-Medium.eot');
  src: local('HelveticaNeueCyr-Medium'),
  url('helvetica-neue/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
  url('helvetica-neue/HelveticaNeueCyr-Medium.woff2') format('woff2'),
  url('helvetica-neue/HelveticaNeueCyr-Medium.woff') format('woff'),
  url('helvetica-neue/HelveticaNeueCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}