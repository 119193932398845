.contacts {
  background: #1A191E;
  padding-top: 100px;
  padding-bottom: 164px;
  &_alt {
    padding-top: 164px;
  }
  @media (max-width: 768px) {
    padding: 64px 0;
    &_alt {
      padding-top: 124px;
    }
  }
  :global(.container) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    color: #ffffff;
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 72px;
    text-align: center;
  }
  &__block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__info {
    background: url("./../../assets/images/contacts/background.svg") center right;
    background-size: cover;
    padding: 48px 48px 96px calc((100vw - 1440px) / 2);
    margin-left: calc(-1 * (100vw - 1440px) / 2);
    @media (max-width: 1440px) {
      margin-left: -24px;
      padding: 48px 48px 96px 24px;
      margin-right: 48px;
    }
    @media (max-width: 1280px) {
      width: calc(100% + 24px);
      margin-right: 0;
      margin-left: -24px;
      margin-bottom: 48px;
    }
    h2 {
      margin-bottom: 48px;
    }
    &-items {
      display: flex;
      flex-direction: column;
      &__item {
        display: flex;
        align-items: center;
        text-decoration: none;
        &:not(:last-child) {
          margin-bottom: 36px;
        }
        div {
          min-width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
          margin-right: 16px;
        }
        span {
          color: #ffffff;
          max-width: 377px;
          font-size: 24px;
          color: #ffffff;
          @media (max-width: 768px) {
            font-size: 18px;
          }
        }
      }
    }
    &-arrow {
      width: 30px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      pointer-events: none;
    }
  }
  &__form {
    width: 800px;
    @media (max-width: 1280px) {
      width: 100%;
    }
    > form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 60px;
      @media (max-width: 768px) {
        gap: 36px;
      }
    }
    &-row {
      display: flex;
      width: 100%;
      gap: 30px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 36px;
      }
    }
  }
}

.advantages {
  padding-bottom: 100px;
  @media (max-width: 1024px) {
    padding-bottom: 64px;
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: -40px;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
    &-item {
      padding: 36px 30px 30px;
      border: 1px solid #E8E8E8;
      box-shadow: 0px 80px 100px rgba(26, 25, 30, 0.05);
      background: #ffffff;
      &:first-child {
        &::before {
          content: '';
          border-bottom: 20px solid #373737;
          border-top: 20px solid transparent;
          border-right: 13px solid #373737;
          border-left: 13px solid transparent;
          position: absolute;
          top: -1px;
          right: calc(100% + 1px);
          @media (max-width: 1500px) {
            display: none;
          }
        }
      }
      &:last-child {
        &::before {
          content: '';
          border-bottom: 20px solid #373737;
          border-top: 20px solid transparent;
          border-left: 13px solid #373737;
          border-right: 13px solid transparent;
          position: absolute;
          top: -1px;
          left: calc(100% + 1px);
          @media (max-width: 1500px) {
            display: none;
          }
        }
      }
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        > div {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          background: #ffffff;
          border: 2px solid #E8E8E8;
        }
      }
      p {
        font-size: 20px;
      }
    }
  }
}